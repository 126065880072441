'use client'
import type { ComponentPropsWithoutRef } from 'react'
import { useEffect } from 'react'
import type { Menu } from '@lib/types'
import type { TypeProviderPublic } from '@lib/provider-types'
import NavBar from '@components/nav-bar'
import { usePathname } from '@i18n/navigation'
import throttle from 'lodash/fp/throttle'
import ProviderAppointmentButton from '@app/[locale]/(main)/doctors/[slug]/_components/provider-appointment-button'

export interface ProviderNavBarProps extends ComponentPropsWithoutRef<'nav'> {
  provider: TypeProviderPublic
  menuItems: Menu
}

const ProviderNavBar = ({ provider, menuItems, ...props }: ProviderNavBarProps) => {
  const pathname = usePathname()

  const throttleMs = 75
  const handleScroll = throttle(throttleMs, () => {
    const mainHeader = document.getElementById('shc-main-header') as HTMLElement
    const mainHeaderHeight = mainHeader.getBoundingClientRect().height
    const navbar = document.getElementById('provider-nav')
    const navbarPosition = navbar !== null ? navbar.getBoundingClientRect() : { top: 0 }
    const cta = document.getElementById('appointment-button-nav')
    if (cta !== null) {
      // The range is determined by some strange variations that occur in iOS,
      // where the navBar position fluxuates +/- 1 px from the position
      // at the bottom of the main header as the user scrolls
      if (navbarPosition.top > mainHeaderHeight - 1 && navbarPosition.top < mainHeaderHeight + 1) {
        cta.style.visibility = 'visible'
      } else {
        cta.style.visibility = 'hidden'
      }
    }
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NavBar
      id="provider-nav"
      alignment="left"
      menu={menuItems}
      activePathname={pathname}
      cta={
        <ProviderAppointmentButton
          id="appointment-button-nav"
          provider={provider}
          buttonType="nav"
        />
      }
    />
  )
}

export default ProviderNavBar
