import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/doctors/[slug]/_components/first-available.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/doctors/[slug]/_components/provider-about.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/doctors/[slug]/_components/provider-appointment-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/doctors/[slug]/_components/provider-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/doctors/[slug]/_components/provider-care-partners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/doctors/[slug]/_components/provider-insurance-plans.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main)/doctors/[slug]/_components/provider-navbar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/analytics.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/client-pagination.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/contentful-image.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/foundation-newsletter-signup.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/newsletter-signup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/page-analytics-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/provider-analytics-wrapper.tsx");
