import type { Dispatch, SetStateAction } from 'react'
import type { TypeProviderPublic } from '@lib/provider-types'
import Modal from '@components/modal'
import { Button, Icon, Link, Typography } from '@shc/ui'
import { useRouter } from '@i18n/navigation'
import { filterParentPlaces } from '@lib/utilities/provider-utilities'

type AppointmentByPhoneProps = {
  provider: TypeProviderPublic
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const AppointmentByPhone = ({
  provider,
  isModalOpen = false,
  setIsModalOpen,
}: AppointmentByPhoneProps) => {
  const router = useRouter()
  const epicProviderWithoutOpenScheduling =
    provider.epic_provider_id && provider.epic_allow_open_scheduling === false

  const onPressSignInForEpic = () => router.push('/app')
  const onPressQuestionsAboutInsurance = () => {
    if (typeof window._uwc === 'function') {
      window._uwc('toggle')
    }
  }

  const placesFilterByTags = filterParentPlaces(provider.places)

  return (
    <Modal
      size="md"
      contentName="Booking"
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      className="flex flex-col">
      <Modal.Title as={Typography} variant="h2" align="center" className="px-7">
        Book appointment
      </Modal.Title>

      {epicProviderWithoutOpenScheduling && (
        <>
          <hr className="bg-gray-200 my-5" />
          <div className="bg-primary-50 p-5 md:-mx-5 my-1 rounded-2xl">
            <div className="flex flex-col gap-y-5">
              <span className="bg-[#D1E6E9] rounded w-fit py-1 px-2 font-semibold text-sm">
                For current patients
              </span>
              <Typography variant="h5">
                <Icon className="h-4 pr-1.5" icon="user" />
                Sign in to schedule
              </Typography>
              <span className="text-base">
                {`Online scheduling may be available if you've seen ${provider.name_formatted_with_degrees} in the last two years.`}
              </span>
              <Button width="md" onClick={onPressSignInForEpic}>
                Sign in
              </Button>
            </div>
          </div>
        </>
      )}

      {/* CALL TO SCHEDULE - LOCATIONS */}

      {!!placesFilterByTags?.length && (
        <>
          <hr className="bg-gray-200 my-5" />
          <div className="flex flex-col gap-y-5">
            <Typography variant="h5">
              <Icon className="h-4 pr-1.5" icon="phone" />
              Call to schedule
            </Typography>
            <div className="flex flex-row justify-between flex-wrap gap-5">
              {placesFilterByTags?.map(
                ({
                  id,
                  name,
                  phone,
                  phone_formatted,
                  fax_formatted,
                  street_address,
                  secondary_address,
                  city,
                  state,
                  postal_code,
                }) => {
                  return (
                    <div key={id} className="flex flex-col gap-2 sm:max-w-[50%]">
                      {name && <div>{name}</div>}
                      {phone && (
                        <div className="text-base font-medium">
                          <Link noUnderline href={`tel:1-${phone}`}>
                            {phone_formatted}
                          </Link>
                          <br />
                          {fax_formatted && `Fax: ${fax_formatted}`}
                        </div>
                      )}
                      {street_address && city && state && postal_code && (
                        <div className="text-base font-medium">
                          {street_address}
                          <br />
                          {secondary_address && (
                            <>
                              {secondary_address}
                              <br />
                            </>
                          )}
                          {`${city}, ${state} ${postal_code}`}
                        </div>
                      )}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </>
      )}

      <hr className="bg-gray-200 my-5" />
      {/* NEED HELP? */}
      <div className="flex flex-col gap-y-5">
        <Typography variant="h5">
          <Icon className="h-4 pr-1.5" icon="circle-question" />
          Need help?
        </Typography>
        <span>
          Questions about insurance coverage or anything else?{' '}
          <Link as="button" onClick={onPressQuestionsAboutInsurance}>
            <span>Chat with us</span>
          </Link>{' '}
          or call{' '}
          <Link href="tel:+18008274277">
            <span>1-800-827-4277</span>
          </Link>{' '}
          to speak to a physician referral nurse.
        </span>
      </div>
    </Modal>
  )
}

export default AppointmentByPhone
