'use client'
import { useState } from 'react'
import type { TypeProviderLayout } from '@lib/utilities/provider-utilities'
import type { TypeProviderPublic } from '@lib/provider-types'
import Modal from '@components/modal'
import VideoPlayer from '@components/video-player'
import { Button, Icon, Link, Typography } from '@shc/ui'

export interface ProviderAboutProps {
  provider: TypeProviderPublic
  providerLayout: TypeProviderLayout
}

const ProviderAbout = ({ provider, providerLayout }: ProviderAboutProps) => {
  const isProviderVideo =
    Array.isArray(provider.video_urls) &&
    provider.video_urls?.length > 0 &&
    provider.video_urls[0] !== ''

  const [isNPIOpen, setIsNPIOpen] = useState(false)
  const [isPpOpen, setIsPpOpen] = useState(false)

  const sortedInterests = Array.isArray(provider.interests)
    ? provider.interests
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    : []

  return (
    <>
      <div className="flex flex-col gap-y-8">
        <section>
          <Typography variant="h2">About {provider.name_formatted_with_degrees}</Typography>
        </section>

        {isProviderVideo && (
          <div>
            <VideoPlayer
              videoUrl={provider.video_urls?.[0]}
              className="w=full"
              data-testid="provider-video"></VideoPlayer>
          </div>
        )}

        {provider.biography && provider.biography?.length > 0 && <div>{provider.biography}</div>}
        <section>
          {provider.age_in_years && provider.age_in_years > 0 && (
            <div>
              <Typography variant="body-semibold" className="inline">
                Age:
              </Typography>
              &nbsp;{provider.age_in_years} <br />
            </div>
          )}
          {provider.year_started_with_sharp && provider.year_started_with_sharp > 0 && (
            <div>
              <Typography variant="body-semibold" className="inline">
                In practice since:
              </Typography>
              &nbsp;{provider.year_started_with_sharp} <br />
            </div>
          )}
          {provider.gender && provider.gender.length > 0 && (
            <div>
              <Typography variant="body-semibold" className="inline">
                Gender:
              </Typography>
              &nbsp;{provider.gender} <br />
            </div>
          )}
          {provider.pronouns && provider.pronouns.length > 0 && (
            <div>
              <Typography variant="body-semibold" className="inline">
                Pronouns:
              </Typography>
              &nbsp;{provider.pronouns} <br />
            </div>
          )}
          {provider.name_pronounciation && provider.name_pronounciation.length > 0 && (
            <div>
              <Typography variant="body-semibold" className="inline">
                Name pronunciation:
              </Typography>
              &nbsp;{provider.name_pronounciation} <br />
            </div>
          )}
          {provider.languages && provider.languages.length > 0 && (
            <div>
              <Typography variant="body-semibold" className="inline">
                Languages:&nbsp;
              </Typography>

              {provider.languages.map((language, index) => {
                return (
                  <span key={language.id}>
                    {index > 0 && ', '}
                    {language.id}
                  </span>
                )
              })}
            </div>
          )}
        </section>

        {provider.educations && provider.educations.length > 0 && (
          <section>
            <div>
              <Typography variant="h5" as="h3">
                Education
              </Typography>
            </div>
            <div className="pt-3">
              {provider.educations.map((college) => {
                // TODO: Fix provider API to report a unique `id`
                return (
                  <div key={`${college.school_id}${college.type}`}>
                    <Typography variant="body-semibold" className="inline">
                      {college.school_name}:
                    </Typography>
                    &nbsp;{college.type} <br />
                  </div>
                )
              })}
            </div>
          </section>
        )}

        {provider.hospital_affiliations && provider.hospital_affiliations.length > 0 && (
          <section>
            <div>
              <Typography variant="h5" as="h3">
                Hospital affiliation
              </Typography>
            </div>

            <div className="pt-3">
              {provider.hospital_affiliations.map((entities) => {
                return (
                  <div key={entities.hospital_id}>
                    <Link
                      noUnderline
                      aria-label="Entity Link"
                      target="_blank"
                      href={entities.hospital_url}>
                      {entities.hospital_name}
                    </Link>
                    <br />
                  </div>
                )
              })}
            </div>
          </section>
        )}

        {provider.interests &&
          provider.interests.length > 0 &&
          providerLayout !== 'Non-referable' && (
            <section className="w-full">
              <div>
                <Typography variant="h5" as="h3">
                  Areas of focus
                </Typography>
              </div>
              <ul className="columns-1 lg:columns-3 pt-3 list-outside ml-5 gap-15">
                {sortedInterests.map((interests) => {
                  return (
                    <li className="list-disc pt-3 break-inside-avoid-column" key={interests.id}>
                      {interests.url && interests.url.length > 0 ? (
                        <Link
                          noUnderline
                          aria-label="Entity Link"
                          target="_blank"
                          href={interests.url}>
                          {interests.name}
                        </Link>
                      ) : (
                        interests.name
                      )}
                    </li>
                  )
                })}
              </ul>
            </section>
          )}
        {provider.interests &&
          provider.interests.length > 0 &&
          providerLayout !== 'Non-referable' && (
            <section>
              <div className="text-sm">
                To confirm coverage for specific treatments and procedures, contact your physician
                or health plan.
              </div>
            </section>
          )}
        <section className="flex flex-col gap-y-2 items-start">
          <div className="flex items-center">
            <Typography variant="h5" as="h3">
              NPI
            </Typography>
            <Button
              color="transparent"
              shape="circle"
              size="xs"
              onClick={() => setIsNPIOpen(true)}
              aria-label="National Provider Identifier"
              data-testid="npi-modal-link">
              <Icon className="h-4 pl-2 text" icon="circle-info" />
            </Button>
          </div>
          <div>{provider.national_provider_id}</div>
        </section>
        {provider.epic_provider_id && (
          <section className="flex flex-col gap-y-3 items-start">
            <div className="flex items-center gap-x-1">
              <Typography variant="h5" as="h3">
                Patient Portal
              </Typography>
              <Button
                color="transparent"
                shape="circle"
                size="xs"
                onClick={() => setIsPpOpen(true)}
                aria-label="Patient Portal"
                data-testid="portal-modal-link">
                <Icon className="h-4 text" icon="circle-info" />
              </Button>
            </div>
            <div>
              <Link
                noUnderline
                href="/app"
                target="_blank"
                aria-label="Sharp App"
                data-testid="sharp-app-link">
                Connect with {provider.name_formatted_with_degrees}, via the Sharp app
              </Link>
            </div>
          </section>
        )}
      </div>

      {/* NPI MODAL */}
      <Modal
        size="sm"
        contentName="NpiModal"
        isOpen={isNPIOpen}
        setIsOpen={setIsNPIOpen}
        className="flex flex-col"
        data-testid="npi-modal">
        <Modal.Description className="text-center">
          <div>
            <Icon className="h-10 text-center text-primary pb-8" icon="circle-info" />
          </div>
          A National Provider Identifier (NPI) is a unique identification number assigned to
          individual U.S. health care providers.
        </Modal.Description>
      </Modal>

      {/* PORTAL MODAL */}
      <Modal
        size="sm"
        contentName="NpiModal"
        isOpen={isPpOpen}
        setIsOpen={setIsPpOpen}
        className="flex flex-col"
        data-testid="npi-modal">
        <Modal.Description className="text-center">
          <div>
            <Icon className="h-10 text-center text-primary pb-8" icon="circle-info" />
          </div>
          Self-service platform to manage health care, including messaging doctors, viewing test
          results and more.
        </Modal.Description>
      </Modal>
    </>
  )
}

export default ProviderAbout
